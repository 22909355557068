import request from '@/utils/request';
import { IResponse } from '@/type/common';

/**
 * 计划API
 */
export const ApiPublic = {
  /**
   *
   * 需要认证
   *
   * @returns
   */
  getPubcliKey(): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: `/public/publicKey`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  send(data: any): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: `/public/decrypt`,
        method: 'POST',
        data: data,
      }).then((res) => {
        resolve(res.data);
      });
    });
  }
};
