import { defineStore } from "pinia";
import { ApiUser } from "@/utils/api";
import { ApiPublic } from "@/api";
import EnMessage from "@/components/message/message";
import { reponseIsSuccess } from "@/utils/response";
import { hybridEncrypt } from "@/utils/encrypt";

export const useUserInfoStore = defineStore("useUserInfoStore", {
  state: () => {
    return {
      token: null,
      userId: "",
      username: "",
      role: "",
      nickname: "",
      profile: "",
    };
  },
  getters: {},
  actions: {
    setToken(token: any) {
      this.token = token;
    },
    removeToken() {
      this.token = null;
    },
    async refresh() {
      const res = await ApiUser.userInfo();
      if (reponseIsSuccess(res.status)) {
        this.userId = res.data.userInfo.userId;
        this.username = res.data.userInfo.username;
        this.nickname = res.data.userInfo.nickname;
        this.profile = res.data.userInfo.profile;
        this.role = res.data.userInfo.role;
      } else {
        EnMessage("获取用户信息失败", "error");
      }
    },
  },
});

export const useSystemInfoStore = defineStore("useSystemInfoStore", {
  state: () => {
    return {
      // staticBaseUrl: 'http://116.204.117.148:8002',
      staticBaseUrl: "http://localhost:5500",
      menu: "1",
      currentTime: "",
      year: 0,
      month: 0,
      day: 0,
      hours: 0,
      minutes: 0,
      pubcliKey: "",
    };
  },
  getters: {},
  actions: {
    updateCurrentTime() {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      let hours = now.getHours();
      let minutes = now.getMinutes();
      let currentTime =
        "" +
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day +
        " " +
        hours +
        ":" +
        minutes;
      this.currentTime = currentTime;
      this.year = year;
      this.month = month;
      this.day = day;
      this.hours = hours;
      this.minutes = minutes;
    },
    async getPublicKey() {
      if (!this.pubcliKey) {
        const res = await ApiPublic.getPubcliKey();
        this.pubcliKey = res.data;
      }
      return this.pubcliKey;
    },
    async login(username: string, password: string, extres?: any) {
      const publicKey = await this.getPublicKey();
      const { iv, encryptedData, encryptedAesKey } = await hybridEncrypt(
        {
          username: username,
          password: password,
        },
        publicKey
      );
      if (encryptedAesKey) {
        return ApiUser.login({
          iv: iv,
          data: encryptedData,
          key: encryptedAesKey,
        });
      } 
      throw console.error("");
      

    },
  },
});

export const useCommonStore = defineStore("useCommonStore", {
  state: () => {
    return {
      publicKey: "",
    };
  },
  getters: {},
  actions: {},
});
