import JSEncrypt from "jsencrypt";
import CryptoJS from "crypto-js";

export const aes = {
  generate() {
    return CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Base64);
  },
  // 使用指定的 AES 加密模式（例如 CBC 或 GCM）
  encrypt(data: any, secretKey: string) {
    const jsonString = JSON.stringify(data);
    const iv = CryptoJS.lib.WordArray.random(16); // 生成随机初始化向量
    const encrypted = CryptoJS.AES.encrypt(
      jsonString,
      CryptoJS.enc.Utf8.parse(secretKey),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC, // 或者 CryptoJS.mode.GCM
        padding: CryptoJS.pad.Pkcs7, // PKCS7 填充
      }
    );

    // 将 IV 和密文拼接在一起，以便解密时使用
    return {
      iv: iv.toString(CryptoJS.enc.Base64),
      encryptedData: encrypted.toString(),
    };
  },
};

export const rsa = {
  encrypt(data: string, secretKey: string) {
    const jsencrypt = new JSEncrypt();
    jsencrypt.setPublicKey(secretKey);
    const result = jsencrypt.encrypt(data);
    // console.log("公钥加密结果:", result)
    return result;
  },
};

// 组合使用 AES 和 RSA 进行混合加密
export const hybridEncrypt = async (data: any, publicKey: string) => {
  // 生成随机 AES 密钥
  const aesKey = CryptoJS.lib.WordArray.random(16).toString(
    CryptoJS.enc.Base64
  ); // 128 位 AES 密钥

  // 使用 AES 加密数据
  const { iv, encryptedData } = aes.encrypt(data, aesKey);

  // 使用 RSA 加密 AES 密钥
  const encryptedAesKey = rsa.encrypt(aesKey, publicKey);

  return {
    iv,
    encryptedData,
    encryptedAesKey,
  };
};
