import request from '@/utils/request';
import type { IResponse, IResult, IPage } from '@/utils/interface/ICommon';
import type {
  TokenResult,
  IUserInfo,
  IUserInfoResult,
} from '@/utils/interface/IUser';
import type { ICategory, ICategoryInfo } from '@/utils/interface/ICategory';
import type { IFirendApply, IFriend } from '@/utils/interface/IFriend';
import type { ApplyHandle, FriendCounter } from '@/utils/obj/OFriend';
import type { IArticleList } from '@/utils/interface/IArticle';
import type { Article } from '@/utils/obj/OArticle';
import type { IStatistics } from '@/utils/interface/IStatistics';
import type { IPicture } from '@/utils/interface/IPicture';
import type { IPlan, IPlanAction } from '@/utils/interface/IPlan';
import type { TimeRange } from '@/utils/obj/TimeRange';
import type { IMessage } from '@/utils/interface/IMessage';
import { Plan } from '@/type/plan';
import type { PictureDto } from '@/utils/type/storage'
import { HybridEncryptData } from '@/utils/type/common'

/**
 * 用户API
 */
export const ApiUser = {
  /**
   *
   *
   * @param params 加密后的用户凭证
   * @returns
   */
  login(params: HybridEncryptData): Promise<IResponse<TokenResult>> {
    return new Promise((resolve) => {
      request({
        url: '/user/login',
        method: 'POST',
        data: params,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 需要身份验证
   *
   * @returns 用户信息
   */
  userInfo(): Promise<IResponse<IUserInfoResult<IUserInfo>>> {
    return new Promise((resolve) => {
      request({
        url: '/user/info',
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 更新用户头像，需要身份验证
   *
   * @param params 头像文件
   * @returns
   */
  uploadProfile(params: FormData): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: '/user/profile',
        method: 'POST',
        data: params,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 更新用户密码，需要身份验证
   *
   * @param oldPassword RSA算法加密后的旧密码
   * @param newPassword RSA算法加密后的新密码
   * @returns
   */
  updateUserPassword(
    oldPassword: string,
    newPassword: string
  ): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: '/user',
        method: 'PUT',
        data: {
          oldPassword: oldPassword,
          newPassword: newPassword,
          sign: 'password',
        },
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

/**
 * 分类API
 */
export const ApiCategory = {
  /**
   * 无需身份验证
   *
   * @returns 获取分类信息
   */
  getCategory(): Promise<IResponse<IResult<ICategory[]>>> {
    return new Promise((resolve) => {
      request({
        url: '/categories',
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 无需身份验证
   *
   * @param id 分类ID
   * @returns 分类信息
   */
  getCategoryById(id: string): Promise<IResponse<ICategory>> {
    return new Promise((resolve) => {
      request({
        url: `/categories/${id}`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 需要身份验证
   *
   * @returns 包含分类信息和用户在该分类的文章数
   */
  getHomeCategoryInfo(): Promise<IResponse<IResult<ICategoryInfo[]>>> {
    return new Promise((resolve) => {
      request({
        url: '/admin/categories',
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 保存分类信息，需要身份验证
   *
   * @param category 分类信息
   * @returns
   */
  saveCategory(category: ICategory): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: '/admin/categories',
        method: 'POST',
        data: category,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 更新分类信息，需要身份验证
   *
   * @param category 分类信息
   * @returns
   */
  updateCategory(category: ICategory): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: '/admin/categories',
        method: 'PUT',
        data: category,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  removeById(id:string): Promise<IResponse<IResult<string>>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/categories/${id}`,
        method: 'DELETE',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
    
  /**
   * 上传分类图片，需要身份验证
   *
   * @param params 包含分类图片文件信息
   * @returns url
   */
  uploadPicture(params: FormData): Promise<IResponse<IResult<string>>> {
    return new Promise((resolve) => {
      request({
        url: '/admin/categories/upload',
        method: 'POST',
        data: params,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

/**
 * 文章API
 */
export const ApiArticle = {
  /**
   * 获取用户的文章列表信息，需要身份验证
   *
   * @param page 当前页
   * @returns 用户的文章列表信息
   */
  getArticleList(page: number): Promise<IResponse<IPage<IArticleList>>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/article?page=${page}&page_size=8`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 获取文章信息，需要身份验证
   *
   * @param id 文章ID
   * @returns
   */
  getArticleById(id: string): Promise<IResponse<Article>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/article/${id}`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 发布文章信息，需要身份验证
   *
   * @param article 文章信息
   * @returns
   */
  releaseArticle(article: Article): Promise<IResponse<IResult<number>>> {
    return new Promise((resolve) => {
      request({
        url: '/admin/article',
        method: 'POST',
        data: article,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 修改文章信息，需要身份验证
   *
   * @param article 文章信息
   * @returns
   */
  saveArticle(article: Article): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: '/admin/article',
        method: 'PUT',
        data: article,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 下架文章
   * @param id 文章ID
   * @returns
   */
  downShelfArticle(id: string): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/article/${id}`,
        method: 'DELETE',
        data: id,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 上传图片，需要身份验证
   *
   * @param params 文件和类型(1,2)
   * @returns
   */
  uploadPicture(params: FormData): Promise<IResponse<IResult<string>>> {
    return new Promise((resolve) => {
      request({
        url: '/admin/article/upload',
        method: 'POST',
        data: params,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

export const ApiMessage = {
  /**
   * 获取用户的文章列表信息，需要身份验证
   *
   * @param page 当前页
   * @returns 用户的文章列表信息
   */
  getMessageList(cond: TimeRange<string>): Promise<IResponse<IPage<IMessage>>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/messages/time`,
        method: 'POST',
        data: cond,
        // headers: {
        //     'Content-Type': 'application/json'
        // }
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  changeStatus(id: string, action: number): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/messages/action`,
        method: 'POST',
        data: {
          action: action,
          messageId: id,
        },
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

/**
 * 友链API
 */
export const ApiFriend = {
  getApplyList(status: number = 0): Promise<IResponse<Array<IFirendApply>>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/friend/apply?status=${status}`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },

  getDataList(status: number): Promise<IResponse<Array<IFriend>>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/friend?status=${status}`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },

  handleApply(handle: ApplyHandle): Promise<IResponse<Array<IFriend>>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/friend/apply`,
        method: 'POST',
        data: handle,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },

  getOriginalDataByApplyId(id: string): Promise<IResponse<IFriend>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/friend/apply/original/${id}`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },

  getCounter(): Promise<IResponse<FriendCounter>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/friend/counter`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

/**
 * 计划API
 */
export const ApiPlan = {
  /**
   *
   * 需要认证
   *
   * @returns
   */
  getPlans(): Promise<IResponse<IResult<IPlan[]>>> {
    return new Promise((resolve) => {
      request({
        url: `/plans`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  createPlan(plan: Plan): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: `/plans`,
        method: 'POST',
        data: plan,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  operationPlan(plan: IPlanAction): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: `/plans`,
        method: 'PUT',
        data: plan,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

/**
 * 图片API
 */
export const ApiPicture = {
  /**
   * 需要身份验证
   *
   * @returns 分类图片列表
   */
  getCategoryPicture(): Promise<IResponse<IPicture[]>> {
    return new Promise((resolve) => {
      request({
        url: '/pictures/category',
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  /**
   * 需要身份验证
   *
   * @returns 文章封面图片列表
   */
  getCoverPicture(): Promise<IResponse<IPicture[]>> {
    return new Promise((resolve) => {
      request({
        url: '/pictures/cover',
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

/**
 * 系统API
 */
export const ApiSystem = {
  /**
   * 获取个人站点统计信息
   *
   * 需要认证
   *
   * @returns
   */
  getStatistics(): Promise<IResponse<IStatistics>> {
    return new Promise((resolve) => {
      request({
        url: `/system/statistics`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

export const ApiNotice = {
  getNotice(): Promise<IResponse<IResult<string>>> {
    return new Promise((resolve) => {
      request({
        url: `/notice`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  updateNotice(notice: string): Promise<IResponse<IResult<string>>> {
    return new Promise((resolve) => {
      request({
        url: `/admin/notice`,
        method: 'POST',
        data: {
          notice: notice,
        },
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

// 存储
export const ApiStorage = {
  upload(params: FormData): Promise<IResponse<IResult<string>>> {
    return new Promise((resolve) => {
      request({
        url: '/admin/storage/upload',
        method: 'POST',
        data: params,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  listByType(type: number): Promise<IResponse<PictureDto[]>>{
    return new Promise((resolve) => {
      request({
        url: `/admin/storage?type=${type}`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  }
}