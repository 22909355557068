import request from '@/utils/request';
import { IResponse } from '@/type/common';
import { PlanResult, PlanAction, Plan } from '@/type/plan';

/**
 * 计划API
 */
export const ApiPlan = {
  /**
   *
   * 需要认证
   *
   * @returns
   */
  getPlans(): Promise<IResponse<PlanResult[]>> {
    return new Promise((resolve) => {
      request({
        url: `/plans`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  createPlan(plan: Plan): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: `/plans`,
        method: 'POST',
        data: plan,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  operationPlan(plan: PlanAction): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: `/plans`,
        method: 'PUT',
        data: plan,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};
